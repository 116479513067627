import { useState } from "react";
import { useBoundingRect } from "../../../../hooks/useBoundingRect";
import { useIsomorphicLayoutEffect } from "../../../../hooks/useIsomorphicLayoutEffect";

export const useSliderNodeHandler = <T extends HTMLElement>(slidesPerView: number, gap: number) => {
    const { ref: sliderRef, dimensions, node: sliderNode } = useBoundingRect<T>();
    const [itemWidth, setItemWidth] = useState<number>(0);

    useIsomorphicLayoutEffect(() => {
        if (sliderNode && dimensions) {
            setItemWidth(dimensions.width / slidesPerView - gap);
            // Make sure the slider is always looking at the first item
            sliderNode.scrollBy(0, 0);
        }
    }, [sliderNode, dimensions]);

    return { sliderRef, itemWidth, sliderNode };
};
