import type { ReactChild, ReactFragment, ReactPortal } from "react";
import { useCallback, useEffect, useState } from "react";

export const useNavigationControls = <T extends HTMLElement>(
    sliderNode: T,
    itemWidth: number,
    arrayChildren: (ReactChild | ReactFragment | ReactPortal)[]
) => {
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isPreviousDisabled, setIsPreviousDisabled] = useState<boolean>(true);
    const [activeSlide, setActiveSlide] = useState<number>(0);

    // scroll into the ref of the active slide
    useEffect(() => {
        if (sliderNode) {
            sliderNode.scrollTo({
                left: activeSlide * itemWidth,
                behavior: "smooth",
            });
        }
    }, [activeSlide, itemWidth, sliderNode]);

    const scrollToSlide = useCallback(
        (nextActiveSlide: number) => {
            if (sliderNode) {
                // Scroll to slide passed as argument
                setActiveSlide(() => {
                    sliderNode.scrollTo({
                        left: nextActiveSlide * itemWidth,
                        behavior: "smooth",
                    });
                    return nextActiveSlide;
                });
            }
        },
        [itemWidth, sliderNode]
    );

    const scrollToNextSlide = useCallback(() => {
        if (sliderNode) {
            // Scroll 1 slide to the right
            setActiveSlide((prevActiveSlide) => {
                const nextActiveSlide = prevActiveSlide + 1;
                sliderNode.scrollTo({
                    left: nextActiveSlide * itemWidth,
                    behavior: "smooth",
                });
                return nextActiveSlide;
            });
        }
    }, [itemWidth, sliderNode]);

    const scrollToPrevSlide = useCallback(() => {
        if (sliderNode) {
            // Scroll 1 slide to the left
            setActiveSlide((prevActiveSlide) => {
                const nextActiveSlide = prevActiveSlide - 1;
                sliderNode.scrollTo({
                    left: nextActiveSlide * itemWidth,
                    behavior: "smooth",
                });
                return nextActiveSlide;
            });
        }
    }, [itemWidth, sliderNode]);

    const scrollToFirstSlide = useCallback(() => {
        if (sliderNode) {
            setActiveSlide(0);
            sliderNode.scrollTo({
                left: 0,
                behavior: "smooth",
            });
        }
    }, [sliderNode]);

    // check if the slide is visible in the viewport and set the active slide accordingly

    useEffect(() => {
        if (sliderNode) {
            const getDisabledState = () => {
                const { scrollLeft, scrollWidth, clientWidth } = sliderNode;
                // -1 is needed to account for the fact that scrollLeft is rounded to the nearest integer
                const isNextDisabled = scrollLeft + clientWidth >= scrollWidth - 1;
                const isPreviousDisabled = scrollLeft === 0;

                setIsNextDisabled(isNextDisabled);
                setIsPreviousDisabled(isPreviousDisabled);
            };

            const observer = new ResizeObserver((entries) => {
                entries.forEach(() => {
                    getDisabledState();
                });
            });

            observer.observe(sliderNode);

            sliderNode.addEventListener("scroll", getDisabledState);

            return () => {
                sliderNode.removeEventListener("scroll", getDisabledState);
                observer.disconnect();
            };
        }
        // We need arrayChildren to be in the dependency array because in some cases when children are first rendered as null
        // and then rendered with actual content, the ResizeObserver doesn't fire and the buttons are disabled. This is a workaround for that.
    }, [sliderNode, arrayChildren]);

    return {
        scrollToNextSlide,
        scrollToPrevSlide,
        isNextDisabled,
        scrollToFirstSlide,
        isPreviousDisabled,
        activeSlide,
        setActiveSlide,
        scrollToSlide,
    };
};
