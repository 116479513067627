import { useEffect, useState } from "react";
import { useThemeMediaQuery } from "../../../../hooks/useThemeMediaQuery";

const parseToArray = (el: number[] | number, length: number) =>
    Array.isArray(el) ? el : (Array.from({ length }).fill(el as number) as number[]);

export const useSlidesPerView = (unparsedSlidesPerView: number[] | number) => {
    const [slidesPerView, setSlidesPerView] = useState<number>(1);
    const { base, md, lg, xl } = useThemeMediaQuery();

    const parsedSlidesPerViewValue = parseToArray(
        unparsedSlidesPerView,
        (unparsedSlidesPerView as number[])?.length || 3
    );

    useEffect(() => {
        if (base) {
            setSlidesPerView(parsedSlidesPerViewValue[0]);
        }
        if (md) {
            setSlidesPerView(parsedSlidesPerViewValue[1] ?? parsedSlidesPerViewValue[0]);
        }
        if (lg) {
            setSlidesPerView(parsedSlidesPerViewValue[2] ?? parsedSlidesPerViewValue[0]);
        }
        if (xl) {
            setSlidesPerView(parsedSlidesPerViewValue[2] ?? parsedSlidesPerViewValue[0]);
        }
    }, [base, md, lg, xl, parsedSlidesPerViewValue]);

    return { slidesPerView };
};
