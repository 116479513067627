import { motion } from "framer-motion";
import type { FC } from "react";
import React from "react";
import { space } from "../../../design-tokens";

interface CustomProgressBarProps {
    value: number;
}

export const CustomProgressBar: FC<CustomProgressBarProps> = ({ value }) => {
    const progressBarStyle: React.CSSProperties = {
        position: "absolute",
        bottom: space["0.2"],
        width: "100%",
        height: space[1],
        backgroundColor: "transparent",
        zIndex: 1,
    };

    const contentVariants = {
        initial: { width: 0 },
        animate: {
            width: `${value}%`,
            transition: { duration: 1 },
        },
    };

    return (
        <motion.div
            style={progressBarStyle}
            initial="initial"
            animate="animate"
            variants={contentVariants}
        >
            <motion.div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#EEEE",
                }}
            ></motion.div>
        </motion.div>
    );
};
